import React, { useState, useEffect, createRef } from "react";
import {
  Button,
  Grid,
  Switch,
  Typography,
  Stack,
  Checkbox,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import huLocale from "date-fns/locale/hu";
import { DatePicker } from "@mui/x-date-pickers";
import { useCookies } from "react-cookie";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import toast, { Toaster } from "react-hot-toast";
import { styled } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import config from "../resources/config.json";
import NavBar from "../components/NavBar.js";
import EtrTable from "../components/Table.js";
import tableHeaders from "../resources/tableHeaders.json";

import "../css/uj.css";
import { LaptopWindowsOutlined } from "@mui/icons-material";

function Uj() {
  const ref = createRef(null);
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const baseString = config.workflow_base;
  let workflow_arr = baseString.split(",");
  const [refresh, setRefresh] = useState(false);
  const [user, setUser] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [law, setLaw] = useState(false);
  const [plannedDate, setPlannedDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reset, setReset] = useState(true);
  const [mui_switch, setSwitch] = useState(false);
  const [users, setUsers] = useState([]);
  const [sajatInputs, setSajatInputs] = useState({
    hatvizs: false,
    regado: false,
  });
  const [ugyfelesInputs, setUgyfelesInputs] = useState({
    hatvizs: false,
    finan: false,
    teljesites: false,
    bizt: false,
    beszamitas: false,
    vam: false,
    regado: false,
  });
  const columns = tableHeaders.newTableHeaders;

  const fillUsers = () => {
    return users.map((e) => {
      return (
        <MenuItem key={e.Username} value={e.Username}>
          {e.Username}
        </MenuItem>
      );
    });
  };

  function fetchData() {
    let temp = [];
    fetch(config.apiurl + "/etr/getCases", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        response.cases.forEach((o) => {
          if (o.new === 1) {
            if (cookies.user.Role === "god") {
              temp.push(o);
            }
            if (cookies.user.Role === "admin") {
              if (cookies.user.TempMakeRights !== "null") {
                if (
                  cookies.user.TempMakeRights.toUpperCase()
                    .split(",")
                    .includes(o.Make.toUpperCase())
                ) {
                  temp.push(o);
                }
              } else {
                if (
                  cookies.user.MakeRights.toUpperCase()
                    .split(",")
                    .includes(o.Make.toUpperCase())
                ) {
                  temp.push(o);
                }
              }
            }
          }
        });
        fetch(config.apiurl + "/etr/getUsers", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Token: cookies.user.Token,
          },
        })
          .then((response) => response.json())
          .then((response) => {
            setUsers(response.users);
          });
        setData(temp);
        setLoading(false);
      });
  }
  const handleChange = (event) => {
    setUser(event.target.value);
  };

  function sendWorkflow() {
    let lawValue;
    if (law) {
      lawValue = 1;
    } else {
      lawValue = 0;
    }

    let postData = {
      caseId: selectedId.original.IDCases,
      workflow: workflow_arr.join(),
      law: lawValue,
    };
    if (cookies.user.Role === "god") {
      postData = {
        ...postData,
        target: user,
      };
    }
    fetch(config.apiurl + "/etr/setWorkflow", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          toast.error("Hiba!");
          console.log(response.error);
        } else {
          console.log(response);
          toast.success("Sikeres!");
        }
      });
  }

  function sendPlannedDate() {
    let postData = {
      caseId: selectedId.original.IDCases,
      plannedDate: plannedDate.toISOString().slice(0, 10).replaceAll("-", "/"),
    };
    if (cookies.user.Role === "god") {
      postData = {
        ...postData,
        target: user,
      };
    }
    fetch(config.apiurl + "/etr/setPlannedDate", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          console.log(response.error);
        } else {
          console.log(response);
          setRefresh(!refresh);
          setSelectedId(null);
        }
      });
  }

  const resetWorkflow_arr = () => {
    workflow_arr = baseString.split(",");
  };

  const removeElement = (arr, e) => {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === e) {
        arr.splice(i, 1);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  useEffect(() => {
    resetWorkflow_arr();
    resetStates();
    if (selectedId) {
      let car = selectedId.original;
      let flag = false;
      if (car.Make === "Suzuki") {
        flag = true;
      }
      if (car.InstanceStatus === "H=Használt") {
        flag = true;
      }
      if (
        car.VehicleCategory === "S=Személygépkocsi" &&
        car.Make === "Opel" &&
        car.Model === "VIVARO-C/ZAFIRA LIFE"
      ) {
        flag = true;
      }
      if (car.VehicleCategory === "H=Haszongépjármű") {
        flag = true;
      }

      if (flag) {
        removeElement(workflow_arr, "regado");
      }
    }
  }, [selectedId]);

  function resetStates() {
    setPlannedDate(new Date());
    setLaw(false);
    setSajatInputs({
      hatvizs: false,
    });
    setUgyfelesInputs({
      hatvizs: false,
      finan: false,
      teljesites: false,
      bizt: false,
      beszamitas: false,
      vam: false,
    });
  }

  function evalUgyfeles() {
    if (!ugyfelesInputs.hatvizs) {
      removeElement(workflow_arr, "hatvizs");
    }
    if (!ugyfelesInputs.finan) {
      removeElement(workflow_arr, "finan");
    }
    if (ugyfelesInputs.teljesites) {
      removeElement(workflow_arr, "teljesites");
      let tempIndex = workflow_arr.indexOf("okmanybol");
      workflow_arr.splice(
        workflow_arr.indexOf("okmanybol") + 1,
        0,
        "teljesites"
      );
    }
    if (!ugyfelesInputs.bizt) {
      removeElement(workflow_arr, "bizt");
    }
    if (!ugyfelesInputs.beszamitas) {
      removeElement(workflow_arr, "beszamitas");
    }
    if (!ugyfelesInputs.vam) {
      removeElement(workflow_arr, "vam");
    }
    if (!ugyfelesInputs.regado) {
      removeElement(workflow_arr, "regado");
    }
    sendWorkflow();
    sendPlannedDate();
    resetWorkflow_arr();
  }

  function evalSajat() {
    removeElement(workflow_arr, "admin");
    removeElement(workflow_arr, "finan");
    removeElement(workflow_arr, "teljesites");
    removeElement(workflow_arr, "szamlazas");
    removeElement(workflow_arr, "vam");
    removeElement(workflow_arr, "beszamitas");
    removeElement(workflow_arr, "atadas");
    if (!sajatInputs.hatvizs) {
      removeElement(workflow_arr, "hatvizs");
    }
    if (!sajatInputs.regado) {
      removeElement(workflow_arr, "regado");
    }
    sendWorkflow();
    sendPlannedDate();
    resetWorkflow_arr();
  }

  return (
    <>
      <NavBar reference={ref} />
      <Toaster position="top-center" />
      <div ref={ref}>
        <Grid container>
          <Grid item xs={9}>
            <div className="ujTable">
              {data.length > 0 ? (
                <EtrTable
                  headers={columns}
                  data={data}
                  isSelectable={true}
                  loading={loading}
                  reset={reset}
                  parentCallBack={(childData) => {
                    setSelectedId(childData);
                  }}
                />
              ) : (
                <div>Nincs Adat</div>
              )}
            </div>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Ügyfeles</Typography>
              <Switch
                color="default"
                checked={mui_switch}
                onChange={(event) => {
                  setSwitch(event.target.checked);
                }}
              />
              <Typography>Saját</Typography>
            </Stack>
            {mui_switch ? (
              <Stack direction="column" spacing={1} alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sajatInputs.hatvizs}
                      onChange={(event) => {
                        setSajatInputs({
                          ...sajatInputs,
                          hatvizs: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Lesz hatósági vizsga?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sajatInputs.regado}
                      onChange={(event) => {
                        setSajatInputs({
                          ...sajatInputs,
                          regado: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Kell-e regadót fizetni?"
                />

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={huLocale}
                >
                  <DatePicker
                    label="Tervezett átadás"
                    minDate={new Date("2012-03-01")}
                    maxDate={new Date("2023-06-01")}
                    value={plannedDate}
                    onChange={(newValue) => {
                      setPlannedDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} />
                    )}
                  />
                </LocalizationProvider>
                {cookies.user.Role === "god" ? (
                  <Stack spacing={2}>
                    <Stack>
                      <InputLabel id="userSelectLabel">Admin</InputLabel>
                      <Select
                        labelId="userSelectLabel"
                        id="userSelect"
                        value={user}
                        label="User"
                        onChange={handleChange}
                      >
                        {fillUsers()}
                      </Select>
                    </Stack>

                    <Button
                      variant="contained"
                      disabled={!user}
                      onClick={evalSajat}
                    >
                      OK
                    </Button>
                  </Stack>
                ) : (
                  <Button variant="contained" onClick={evalSajat}>
                    OK
                  </Button>
                )}
              </Stack>
            ) : (
              <Stack direction="column" spacing={1} alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ugyfelesInputs.hatvizs}
                      onChange={(event) => {
                        setUgyfelesInputs({
                          ...ugyfelesInputs,
                          hatvizs: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Lesz hatósági vizsga?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ugyfelesInputs.finan}
                      onChange={(event) => {
                        setUgyfelesInputs({
                          ...ugyfelesInputs,
                          finan: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Van-e finanszírozás?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ugyfelesInputs.teljesites}
                      onChange={(event) => {
                        setUgyfelesInputs({
                          ...ugyfelesInputs,
                          teljesites: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Pénzügyi teljesítés okmány után lesz?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ugyfelesInputs.bizt}
                      onChange={(event) => {
                        setUgyfelesInputs({
                          ...ugyfelesInputs,
                          bizt: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Velünk köt biztosítást?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ugyfelesInputs.beszamitas}
                      onChange={(event) => {
                        setUgyfelesInputs({
                          ...ugyfelesInputs,
                          beszamitas: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Lesz beszámítás?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ugyfelesInputs.regado}
                      onChange={(event) => {
                        setUgyfelesInputs({
                          ...ugyfelesInputs,
                          regado: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Kell-e regadót fizetni?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ugyfelesInputs.vam}
                      onChange={(event) => {
                        setUgyfelesInputs({
                          ...ugyfelesInputs,
                          vam: event.target.checked,
                        });
                      }}
                    />
                  }
                  label="Külföldre viszik az autót?"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={law}
                      onChange={(event) => {
                        setLaw(event.target.checked);
                      }}
                    />
                  }
                  label="Jogi személy?"
                />
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={huLocale}
                >
                  <DatePicker
                    label="Tervezett átadás"
                    minDate={new Date("2012-03-01")}
                    maxDate={new Date("2023-06-01")}
                    value={plannedDate}
                    onChange={(newValue) => {
                      setPlannedDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} />
                    )}
                  />
                </LocalizationProvider>
                {cookies.user.Role === "god" ? (
                  <Stack spacing={2}>
                    <Stack>
                      <InputLabel id="userSelectLabel">Admin</InputLabel>
                      <Select
                        labelId="userSelectLabel"
                        id="userSelect"
                        value={user}
                        label="User"
                        onChange={handleChange}
                      >
                        {fillUsers()}
                      </Select>
                    </Stack>
                    <Button
                      variant="contained"
                      disabled={!user}
                      onClick={evalUgyfeles}
                    >
                      OK
                    </Button>
                  </Stack>
                ) : (
                  <Button variant="contained" onClick={evalUgyfeles}>
                    OK
                  </Button>
                )}
              </Stack>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Uj;
