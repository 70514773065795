import React from "react";
import ReactDOM from "react-dom";
import { CookiesProvider, useCookies } from "react-cookie";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "./css/index.css";
import Forgalom from "./routes/forgalom";
import Tervezes from "./routes/tervezes";
import Login from "./routes/login";
import Uj from "./routes/uj";
import Settings from "./routes/settings";
import config from "./resources/config.json";

ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <>
          <Route
            path="/"
            element={
              <RequireAuth>
                <GoToTervezes />
              </RequireAuth>
            }
          />
          <Route
            path="/tervezes"
            element={
              <RequireAuth>
                <Tervezes />
              </RequireAuth>
            }
          />
          <Route
            path="/forgalom"
            element={
              <RequireAuth>
                <Forgalom />
              </RequireAuth>
            }
          />
          <Route
            path="/uj"
            element={
              <RequirePerm notAllowed={["ertekesito"]}>
                <RequireAuth>
                  <Uj />
                </RequireAuth>
              </RequirePerm>
            }
          />
          <Route
            path="/beallitasok"
            element={
              <RequirePerm notAllowed={["ertekesito", "admin"]}>
                <RequireAuth>
                  <Settings />
                </RequireAuth>
              </RequirePerm>
            }
          />
        </>
      </Routes>
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById("root")
);

function RequireAuth({ children }) {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  if (cookies.user === undefined) {
    return <GoToLogin />;
  }

  return children;
}

function RequirePerm({ children, notAllowed }) {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  let perm = true;

  if (notAllowed.includes(cookies.user.Role)) {
    perm = false;
  }

  if (perm) {
    return children;
  } else {
    return <GoToTervezes />;
  }
}

function GoToLogin() {
  let location = useLocation();
  return <Navigate to="/login" state={{ from: location }} />;
}

function GoToTervezes() {
  let location = useLocation();
  return <Navigate to="/tervezes" state={{ from: location }} />;
}

function GoToForgalom() {
  let location = useLocation();
  return <Navigate to="/forgalom" state={{ from: location }} />;
}
