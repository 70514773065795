import { useState, useEffect, createRef } from "react";
import EtrTable from "../components/Table.js";
import tableHeaders from "../resources/tableHeaders.json";
import { confirmAlert } from "react-confirm-alert";
import { useCookies } from "react-cookie";
import NavBar from "../components/NavBar.js";
import "../css/confirmAlert.css";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Stack,
  Popover,
  TextField,
} from "@mui/material";

import config from "../resources/config.json";
import EditWorkflowDialog from "../components/forgalom/EditWorkflowDialog.js";
import TaskList from "../components/forgalom/TaskList.js";
import "../css/forgalom.css";
import toast from "react-hot-toast";

function Forgalom() {
  const ref = createRef(null);
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [selectedTasks, setSelectedTasks] = useState(null);
  const [remainingTasks, setRemainingTasks] = useState(null);
  const [allTasks, setAllTasks] = useState(null);
  const [reset, setReset] = useState(true);
  const [refreshTasks, setRefreshTasks] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [workingData, setWorkingData] = useState(data);
  const [filterData, setFilterData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [tasks, setTasks] = useState({});
  const [tooltip, setTooltip] = useState(null);
  const [type, setType] = useState(null);
  const [comment, setComment] = useState("");
  const [commentDisable, setCommentDisable] = useState(true);
  const [details, setDetails] = useState({
    InstanceStatus: "",
    modifiedBy: "",
    VehicleCategory: "",
    Make: "",
    PipeDate_T: "",
    CustomerName: "",
  });
  const columns1 = tableHeaders.workFlowTableHeaders;
  const [inputs, setInputs] = useState({
    CustomerName: "Összes",
    workflow_state: "Összes",
    modifiedBy: "Összes",
  });
  const [tasks1Name, setTasks1Name] = useState("");
  const [tasks1, setTasks1] = useState([]);
  const [workingTasks1, setWorkingTasks1] = useState(null);

  const [tasks2Name, setTasks2Name] = useState("");
  const [workingTasks2, setWorkingTasks2] = useState(null);

  const [tasks3Name, setTasks3Name] = useState("");
  const [workingTasks3, setWorkingTasks3] = useState(null);

  const [tasks4Name, setTasks4Name] = useState("");
  const [workingTasks4, setWorkingTasks4] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  //popover---------------------------------------
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCommentDisable(true);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  //-------------------------------------------------

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const checkIfDone = (answerArray, taskArray, name) => {
    let arrayCount = 0;
    let taskCount = 0;
    answerArray.forEach((e) => {
      if (e.taskId.includes(name)) arrayCount++;
    });
    taskArray.forEach((e) => {
      if (e.IDTask.includes(name)) taskCount++;
    });
    return arrayCount === taskCount;
  };

  //TODO: frissítés megoldani minden változás után
  const handleToggle = (value, arr, setter, result) => () => {
    confirmAlert({
      title: "Megerősítés",
      message: "Biztos elvégezte a feladatot?",
      buttons: [
        {
          label: "Igen",
          onClick: () => {
            let postData = {
              caseId: selectedId.original.IDCases,
              taskId: value,
              answer: result,
            };
            const currentIndex = arr.indexOf(value);
            const newChecked = [...arr];
            if (currentIndex === -1) {
              newChecked.push(value);
            } else {
              newChecked.splice(currentIndex, 1);
            }
            if (cookies.user.Role === "ertekesito") {
              toast.error("Csak adminnak lehetséges!");
            } else {
              fetch(config.apiurl + "/etr/setAnswer", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Token: cookies.user.Token,
                },
                body: JSON.stringify(postData),
              })
                .then((response) => response.json())
                .then((response) => {
                  if (response.error) {
                    console.log(response.error);
                  } else {
                    if (!response.workflowState) {
                      setSelectedId(null);
                      setReset(!reset);
                    }
                  }
                })
                .then((e) => {
                  setter(newChecked);
                  fetchData();
                  setWorkingData(data);
                  setRemainingTasks(remainingTasks + 1);
                });
            }
          },
        },
        {
          label: "Nem",
        },
      ],
    });
  };

  function fetchData() {
    let temp = [];
    let doneWorkflowString = "";
    let todoWorkflowString = "";
    fetch(config.apiurl + "/etr/getCases", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          console.log(response.error);
        } else {
          response.cases.forEach((o) => {
            if (o.active === 1 && o.new === 0) {
              if (o.plannedDate) {
                o.plannedDate = o.plannedDate
                  .toString()
                  .slice(0, 10)
                  .replaceAll("-", ".");
              }
              if (o.CustomerName) {
                o.CustomerName = o.CustomerName.toUpperCase();
              }
              if (cookies.user.Role === "ertekesito") {
                if (o.Salesman === cookies.user.FullName) {
                  temp.push(o);
                }
              }
              if (cookies.user.Role === "admin") {
                if (o.modifiedBy === cookies.user.FullName) {
                  temp.push(o);
                } else if (cookies.user.responsibilities !== "null") {
                  if (
                    cookies.user.responsibilities
                      .split(",")
                      .includes(o.modifiedBy)
                  ) {
                    temp.push(o);
                  }
                }
              }
              if (cookies.user.Role === "god") {
                temp.push(o);
              }
            }
          });
          setFilterData(temp);
          fetch(config.apiurl + "/etr/getWorkflows", {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Token: cookies.user.Token,
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.error) {
                console.log(response.error);
              } else {
                setWorkflows(response.workflows);
                let tempWorkflows = response.workflows;
                temp.forEach((o) => {
                  o.workflow_state_id = o.workflow_state;
                  o.workflow_state = response.workflows.find(
                    (obj) => obj.id === o.workflow_state
                  ).name;
                });

                fetch(config.apiurl + "/etr/getTasks", {
                  method: "GET",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Token: cookies.user.Token,
                  },
                })
                  .then((response) => response.json())
                  .then((response) => {
                    if (response.error) {
                      console.log(response.error);
                    } else {
                      temp.forEach((o) => {
                        let tempArr = response.tasks;

                        if (o.InstanceStatus !== "H=Használt") {
                          tempArr = tempArr.filter(
                            (obj) => !obj.IDTask.includes("_H_")
                          );
                        }

                        if (o.Law === 0) {
                          tempArr = tempArr.filter(
                            (obj) => !obj.IDTask.includes("_J_")
                          );
                        }

                        if (o.InstanceStatus !== "T=Taktikai forg.hely") {
                          tempArr = tempArr.filter(
                            (obj) => !obj.IDTask.includes("_T_")
                          );
                        }

                        let tempAllTasks = 0;
                        Object.entries(tempArr).forEach((e) => {
                          if (
                            o.workflow.includes(
                              e[1].IDTask.substring(0, e[1].IDTask.indexOf("_"))
                            )
                          ) {
                            tempAllTasks++;
                          }
                        });

                        o.tasksLeft = o.answers.length + "/" + tempAllTasks;

                        //TODO: szerződésszám
                        o.workflow.forEach((w) => {
                          if (checkIfDone(o.answers, tempArr, w)) {
                            doneWorkflowString =
                              doneWorkflowString +
                              tempWorkflows.find((obj) => obj.id === w).name +
                              " > ";
                          } else {
                            todoWorkflowString =
                              todoWorkflowString +
                              tempWorkflows.find((obj) => obj.id === w).name +
                              " > ";
                          }
                        });
                        doneWorkflowString = doneWorkflowString.slice(0, -2);
                        todoWorkflowString = todoWorkflowString.slice(0, -2);
                        o.workflow_string_done = doneWorkflowString;
                        o.workflow_string_todo = todoWorkflowString;
                        doneWorkflowString = "";
                        todoWorkflowString = "";
                      });
                      let tempTooltip = [];
                      response.tasks.forEach((e) => {
                        tempTooltip.push({
                          IDTask: e.IDTask,
                          tooltip: e.tooltip,
                        });
                      });
                      let tempType = [];
                      response.tasks.forEach((e) => {
                        tempType.push({
                          IDTask: e.IDTask,
                          type: e.type,
                        });
                      });
                      setType(tempType);
                      setTooltip(tempTooltip);
                      setData(temp);
                      setWorkingData(temp);
                      setTasks(response.tasks);
                      setLoading(false);
                    }
                  });
              }
            });
        }
      });
  }

  function sendData(value) {
    let postData = {
      caseId: selectedId.original.IDCases,
      workflow: value.workflow,
      law: value.law,
    };
    fetch(config.apiurl + "/etr/setWorkflow", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          console.log(response.error);
        } else {
          console.log(response);
        }
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  function setTask(name, working, offset) {
    let temp_string = "";
    let tempArr = [];
    selectedId.original.workflow.forEach((e) => {
      tempArr.push(workflows.find((obj) => obj.id === e).id);
    });
    for (var i = 0; i < selectedId.original.workflow.length; i++) {
      if (tempArr[i] === selectedTasks) {
        if (selectedId.original.workflow[i + offset]) {
          temp_string = selectedId.original.workflow[i + offset];
        }
      }
    }
    if (workflows.find((obj) => obj.id === temp_string)) {
      name(workflows.find((obj) => obj.id === temp_string).name);
      working(tasks.filter((e) => e.IDTask.includes(temp_string)));
    } else {
      name("");
      working(null);
    }
  }

  useEffect(() => {
    if (selectedId) {
      setWorkingTasks1(tasks.filter((e) => e.IDTask.includes(selectedTasks)));
      setTasks1Name(workflows.find((obj) => obj.id === selectedTasks).name);
      setTask(setTasks2Name, setWorkingTasks2, 1);
      setTask(setTasks3Name, setWorkingTasks3, 2);
      setTask(setTasks4Name, setWorkingTasks4, 3);
    }
  }, [refreshTasks]);

  /*useEffect(() => {
    setSelectedId(null);
    setReset(!reset);
  }, [inputs]);*/

  useEffect(() => {
    if (selectedId) {
      if (selectedId.original.Comment) {
        setComment(selectedId.original.Comment);
      }
      let tempArr = tasks;

      if (selectedId.original.InstanceStatus !== "H=Használt") {
        tempArr = tempArr.filter((obj) => !obj.IDTask.includes("_H_"));
      }

      if (selectedId.original.Law === 0) {
        tempArr = tempArr.filter((obj) => !obj.IDTask.includes("_J_"));
      }

      if (selectedId.original.InstanceStatus !== "T=Taktikai forg.hely") {
        tempArr = tempArr.filter((obj) => !obj.IDTask.includes("_T_"));
      }

      //TODO: szerződésszám

      let tempAllTasks = 0;
      Object.entries(tempArr).forEach((e) => {
        if (
          selectedId.original.workflow.includes(
            e[1].IDTask.substring(0, e[1].IDTask.indexOf("_"))
          )
        ) {
          tempAllTasks++;
        }
      });
      setAllTasks(tempAllTasks);
      setRemainingTasks(selectedId.original.answers.length);

      setSelectedTasks(selectedId.original.workflow_state_id);
      let temp = [];
      setDetails((values) => ({
        ...values,
        InstanceStatus: selectedId.original.InstanceStatus,
        modifiedBy: selectedId.original.modifiedBy,
        VehicleCategory: selectedId.original.VehicleCategory,
        Make: selectedId.original.Make,
        PipeDate_T: selectedId.original.PipeDate_T,
        CustomerName: selectedId.original.CustomerName,
      }));
      selectedId.original.answers.forEach((o) => {
        temp.push(o.taskId);
      });
      setTasks1(temp);
      setWorkingTasks1(null);
      setWorkingTasks2(null);
      setWorkingTasks3(null);
      setWorkingTasks4(null);
      setRefreshTasks(!refreshTasks);
    } else {
      setRemainingTasks(null);
      setDetails({
        InstanceStatus: "",
        modifiedBy: "",
        VehicleCategory: "",
        Make: "",
        PipeDate_T: "",
        CustomerName: "",
      });
      setWorkingTasks1(null);
      setWorkingTasks2(null);
      setWorkingTasks3(null);
      setWorkingTasks4(null);
      setSelectedTasks(null);
      setTasks4Name(null);
      setTasks3Name(null);
      setTasks2Name(null);
      setTasks1Name(null);
    }
  }, [selectedId]);

  useEffect(() => {
    let result1 = data;
    //CustomerName filter
    if (inputs.CustomerName !== "Összes") {
      result1 = result1.filter((e) => {
        return e.CustomerName === inputs.CustomerName;
      });
    }

    //InstanceStatus filter
    if (inputs.workflow_state !== "Összes") {
      result1 = result1.filter((e) => {
        return e.workflow_state === inputs.workflow_state;
      });
    }

    //modifiedBy filter
    if (inputs.modifiedBy !== "Összes") {
      result1 = result1.filter((e) => {
        return e.modifiedBy === inputs.modifiedBy;
      });
    }

    setWorkingData(result1);
  }, [inputs, data]);

  function updateCase(value) {
    let postData;
    if (value.law === "0") {
      postData = {
        IDCase: selectedId.original.IDCases,
        law: 0,
        InstanceStatus: value.status,
      };
    } else {
      postData = {
        IDCase: selectedId.original.IDCases,
        law: 1,
        InstanceStatus: value.status,
      };
    }

    fetch(config.apiurl + "/etr/updateCase", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          console.log(response.error);
        }
      });
  }

  const handleEditDialogClose = (value) => {
    console.log(value);
    if (value) {
      sendData(value);
      updateCase(value);
      window.location.reload();
    }
    setOpenEditDialog(false);
    // Return value is null if no changes were made, and joint string if was ex. "admin,bizt,finan"
  };

  const fillCustomer = () => {
    let temp = [];
    filterData.forEach((o) => {
      if (temp.indexOf(o.CustomerName) === -1) {
        temp.push(o.CustomerName);
      }
    });
    temp.sort();
    temp.push("Összes");
    return temp.map((e) => {
      let flag = false;
      workingData.forEach((o) => {
        if (o.CustomerName === e) {
          flag = true;
        }
      });
      if (flag || e === "Összes") {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#000000" }}>
            {e}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#a8a8a8" }}>
            {e}
          </MenuItem>
        );
      }
    });
  };

  const fillState = () => {
    let temp = [];
    filterData.forEach((o) => {
      if (temp.indexOf(o.workflow_state) === -1) {
        temp.push(o.workflow_state);
      }
    });
    temp.sort();
    temp.push("Összes");
    return temp.map((e) => {
      let flag = false;
      workingData.forEach((o) => {
        if (o.workflow_state === e) {
          flag = true;
        }
      });
      if (flag || e === "Összes") {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#000000" }}>
            {e}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#a8a8a8" }}>
            {e}
          </MenuItem>
        );
      }
    });
  };

  const fillmodifiedBy = () => {
    let temp = [];
    filterData.forEach((o) => {
      if (temp.indexOf(o.modifiedBy) === -1) {
        temp.push(o.modifiedBy);
      }
    });
    temp.sort();
    temp.push("Összes");
    return temp.map((e) => {
      let flag = false;
      workingData.forEach((o) => {
        if (o.modifiedBy === e) {
          flag = true;
        }
      });
      if (flag || e === "Összes") {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#000000" }}>
            {e}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#a8a8a8" }}>
            {e}
          </MenuItem>
        );
      }
    });
  };

  return (
    <>
      <NavBar reference={ref} />
      <div ref={ref}>
        <Stack direction="row" spacing={1} alignItems="center">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="CustomerName-label">Vevő</InputLabel>
            <Select
              labelId="CustomerName-label"
              label="Vevő"
              id="CustomerName"
              name="CustomerName"
              value={inputs.CustomerName}
              onChange={handleChange}
            >
              {fillCustomer()}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="workflow_state-label">Státusz</InputLabel>
            <Select
              labelId="workflow_state-label"
              label="Státusz"
              id="workflow_state"
              name="workflow_state"
              value={inputs.workflow_state}
              onChange={handleChange}
            >
              {fillState()}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="modifiedBy-label">Felelős</InputLabel>
            <Select
              labelId="modifiedBy-label"
              label="Felelős"
              id="modifiedBy"
              name="modifiedBy"
              value={inputs.modifiedBy}
              onChange={handleChange}
            >
              {fillmodifiedBy()}
            </Select>
          </FormControl>

          <Button
            variant="outlined"
            onClick={() => {
              setInputs((values) => ({
                ...values,
                CustomerName: "Összes",
                workflow_state: "Összes",
                modifiedBy: "Összes",
              }));
            }}
          >
            Reset
          </Button>

          {/* TODO - change button style, it's only being used as a placeholder */}
          <Button
            variant="outlined"
            onClick={() => {
              setOpenEditDialog(true);
            }}
          >
            Szerkesztés
          </Button>
        </Stack>

        <EditWorkflowDialog
          open={openEditDialog}
          selectedId={selectedId}
          data={workingData}
          workflows={workflows}
          onClose={(value) => handleEditDialogClose(value)}
        />

        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={9} sx={{ height: "100%" }}>
            <div className="scrollableDiv">
              <div className="scrollableTable">
                {workingData.length > 0 ? (
                  <EtrTable
                    headers={columns1}
                    data={workingData}
                    isSelectable={true}
                    loading={loading}
                    reset={reset}
                    parentCallBack={(childData) => {
                      setSelectedId(childData);
                    }}
                  />
                ) : (
                  <div>Nincs Adat</div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={() => {
                if (selectedId) {
                  const temp =
                    selectedId.original.workflow.indexOf(selectedTasks);
                  if (selectedId.original.workflow[temp - 1]) {
                    setSelectedTasks(selectedId.original.workflow[temp - 1]);
                    setRefreshTasks(!refreshTasks);
                  }
                }
              }}
            >
              &#8592;
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (selectedId) {
                  const temp =
                    selectedId.original.workflow.indexOf(selectedTasks);
                  if (selectedId.original.workflow[temp + 1]) {
                    setSelectedTasks(selectedId.original.workflow[temp + 1]);
                    setRefreshTasks(!refreshTasks);
                  }
                }
              }}
            >
              &#8594;
            </Button>

            <Button
              variant="outlined"
              sx={
                selectedId
                  ? selectedId.original.Comment
                    ? { color: "green" }
                    : { color: "blue" }
                  : { color: "blue" }
              }
              onClick={handleClick}
              disabled={!selectedId}
            >
              Megjegyzés
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <TextField
                label="Megjegyzés"
                sx={{ margin: "10px", width: "500px" }}
                variant="outlined"
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
                disabled={commentDisable}
                multiline
              />
              <Stack column>
                <Button
                  variant="outlined"
                  sx={{ margin: "10px" }}
                  onClick={() => {
                    setCommentDisable(false);
                  }}
                >
                  Szerkesztés
                </Button>
                <Button
                  variant="outlined"
                  sx={{ margin: "10px", color: "green" }}
                  onClick={() => {
                    const postData = {
                      caseID: selectedId.original.IDCases,
                      comment: comment,
                    };
                    fetch(config.apiurl + "/etr/setComment", {
                      method: "POST",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Token: cookies.user.Token,
                      },
                      body: JSON.stringify(postData),
                    })
                      .then((response) => response.json())
                      .then((response) => {
                        if (response.error) {
                          toast.error("Hiba!");
                          console.log(response.error);
                        } else {
                          console.log(response);
                          toast.success("Sikeres!");
                          window.location.reload();
                        }
                      });
                    setAnchorEl(null);
                  }}
                >
                  Mentés
                </Button>
              </Stack>
            </Popover>

            <TaskList
              style={{
                margin: "5px",
              }}
              taskName={tasks1Name}
              tasks={workingTasks1}
              currentTasks={tasks1}
              setter={setTasks1}
              handleFunc={handleToggle}
              tooltip={tooltip}
              type={type}
              selected={selectedId}
            />

            <TaskList
              style={{
                margin: "5px",
              }}
              taskName={tasks2Name}
              tasks={workingTasks2}
              currentTasks={tasks1}
              setter={setTasks1}
              handleFunc={handleToggle}
              tooltip={tooltip}
              type={type}
              selected={selectedId}
            />

            <TaskList
              style={{
                margin: "5px",
              }}
              taskName={tasks3Name}
              tasks={workingTasks3}
              currentTasks={tasks1}
              setter={setTasks1}
              handleFunc={handleToggle}
              tooltip={tooltip}
              type={type}
              selected={selectedId}
            />

            <TaskList
              style={{
                margin: "5px",
              }}
              taskName={tasks4Name}
              tasks={workingTasks4}
              currentTasks={tasks1}
              setter={setTasks1}
              handleFunc={handleToggle}
              tooltip={tooltip}
              type={type}
              selected={selectedId}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
export default Forgalom;
