import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import huLocale from "date-fns/locale/hu";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Tooltip,
  Zoom,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Input,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "../../css/taskList.css";

function TaskList({
  taskName,
  tasks,
  currentTasks,
  setter,
  handleFunc,
  style,
  tooltip,
  type,
  selected,
}) {
  const [radioAnswer, setRadioAnswer] = useState("");
  const [dateValue, setDateValue] = useState(new Date());
  const [dateAnswer, setDateAnswer] = useState("");
  const [inputAnswer, setInputAnswer] = useState("");
  const [filteredTasks, setFilteredTasks] = useState(null);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleChangeInput = (e) => {
    setInputAnswer(e.target.value);
  };

  useEffect(() => {
    if (tasks) {
      let tempArr = tasks;

      if (selected !== null) {
        if (selected.original.InstanceStatus !== "H=Használt") {
          tempArr = tempArr.filter((obj) => !obj.IDTask.includes("_H_"));
        }

        if (selected.original.Law === 0) {
          tempArr = tempArr.filter((obj) => !obj.IDTask.includes("_J_"));
        }

        if (selected.original.InstanceStatus !== "T=Taktikai forg.hely") {
          tempArr = tempArr.filter((obj) => !obj.IDTask.includes("_T_"));
        }

        //TODO: szerződésszám
      }
      setFilteredTasks(tempArr);
    } else {
      setFilteredTasks(null);
    }
  }, [tasks]);

  return (
    <Item sx={style}>
      <b>
        <div>{taskName}</div>
      </b>
      {!filteredTasks ? (
        <>
          <div>válasszon ki egy elemet!</div>
        </>
      ) : (
        <List dense={true}>
          {filteredTasks.map((value) => {
            const labelId = `checkbox-list-label-${value.IDTask}`;
            let tooltipText = tooltip.filter((obj) => {
              return obj.IDTask === value.IDTask;
            });
            tooltipText = tooltipText[0].tooltip;
            let typeChar = type.filter((obj) => {
              return obj.IDTask === value.IDTask;
            })[0].type;

            return (
              <ListItem key={value.IDTask} disablePadding>
                {typeChar === "C" ? (
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={currentTasks.indexOf(value.IDTask) !== -1}
                      tabIndex={-1}
                      onChange={handleFunc(
                        value.IDTask,
                        currentTasks,
                        setter,
                        1
                      )}
                      disableRipple
                      disabled={currentTasks.indexOf(value.IDTask) !== -1}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                ) : typeChar === "B" ? (
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={currentTasks.indexOf(value.IDTask) !== -1}
                      tabIndex={-1}
                      onChange={handleFunc(
                        value.IDTask,
                        currentTasks,
                        setter,
                        radioAnswer
                      )}
                      disableRipple
                      disabled={
                        currentTasks.indexOf(value.IDTask) !== -1 ||
                        radioAnswer === ""
                      }
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                ) : typeChar === "D" ? (
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={currentTasks.indexOf(value.IDTask) !== -1}
                      tabIndex={-1}
                      onChange={handleFunc(
                        value.IDTask,
                        currentTasks,
                        setter,
                        dateAnswer
                      )}
                      disableRipple
                      disabled={
                        currentTasks.indexOf(value.IDTask) !== -1 ||
                        dateAnswer === ""
                      }
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                ) : typeChar === "I" ? (
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={currentTasks.indexOf(value.IDTask) !== -1}
                      tabIndex={-1}
                      onChange={handleFunc(
                        value.IDTask,
                        currentTasks,
                        setter,
                        inputAnswer
                      )}
                      disableRipple
                      disabled={
                        currentTasks.indexOf(value.IDTask) !== -1 ||
                        inputAnswer === ""
                      }
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                ) : null}
                <Tooltip
                  title={tooltipText}
                  placement="top-start"
                  TransitionComponent={Zoom}
                >
                  <ListItemText id={labelId} primary={value.taskDetail} />
                </Tooltip>
                {currentTasks.indexOf(value.IDTask) !== -1 ? null : typeChar ===
                  "B" ? (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    className="listComponent"
                    row
                    value={radioAnswer}
                    onChange={(ev) => {
                      setRadioAnswer(ev.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="Igen"
                      control={<Radio />}
                      label="Igen"
                    />
                    <FormControlLabel
                      value="Nem"
                      control={<Radio />}
                      label="Nem"
                    />
                  </RadioGroup>
                ) : typeChar === "D" ? (
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={huLocale}
                  >
                    <DatePicker
                      label="Dátum"
                      inputFormat="yyyy/MM/dd"
                      mask="____/__/__"
                      minDate={new Date("2012-03-01")}
                      maxDate={new Date("2023-06-01")}
                      value={dateValue}
                      onChange={(newValue) => {
                        setDateValue(newValue);
                        setDateAnswer(newValue.toISOString().slice(0, 10));
                      }}
                      renderInput={(params) => (
                        <TextField {...params} helperText={null} />
                      )}
                    />
                  </LocalizationProvider>
                ) : typeChar === "I" ? (
                  <Input
                    autoFocus
                    placeholder="Válasz"
                    value={inputAnswer}
                    onChange={handleChangeInput}
                  />
                ) : null}
              </ListItem>
            );
          })}
        </List>
      )}
    </Item>
  );
}

export default TaskList;
