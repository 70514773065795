import { useState, useEffect, createRef } from "react";
import EtrTable from "../components/Table.js";
import tableHeaders from "../resources/tableHeaders.json";
import huLocale from "date-fns/locale/hu";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import NavBar from "../components/NavBar.js";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useCookies } from "react-cookie";
import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Stack,
  Popper,
} from "@mui/material";

import config from "../resources/config.json";

import "../css/tervezes.css";
import cookie from "react-cookies";

function Tervezes() {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [dateValue, setDateValue] = useState(new Date());
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState({
    VehicleCategory: "Összes",
    Make: "Összes",
    Model: "Összes",
    Salesman: "Összes",
    InstanceStatus: "Összes",
  });
  const [grid, setGrid] = useState({
    ster: 0,
    hter: 0,
    smar: 0,
    hmar: 0,
  });

  const [data1, setData1] = useState([]);
  const [workingData1, setWorkingData1] = useState(data1);
  const [data2, setData2] = useState([]);
  const [workingData2, setWorkingData2] = useState(data2);
  const [errorMessage, setErrorMessage] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const columns1 = tableHeaders.plannedTableHeaders;
  const columns2 = tableHeaders.doneTableHeaders;
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  function fetchData() {
    const dateFilter = dateValue.toISOString().slice(0, 7);
    let temp1 = [];
    let temp2 = [];
    fetch(config.apiurl + `/etr/getMonthly?date=${dateFilter}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          console.log(response.error);
          setErrorMessage(response.error);
        } else {
          if (cookies.user.DefaultMake) {
            setInputs((values) => ({
              ...values,
              Make: cookies.user.DefaultMake,
            }));
          }

          let temp = [];
          if (cookies.user.Role === "ertekesito") {
            response.cases.forEach((o) => {
              if (o.Salesman === cookies.user.FullName) {
                temp.push(o);
              }
            });
          } else {
            temp = response.cases;
          }

          temp.forEach((o) => {
            if (o.PipeDate_T) {
              o.PipeDate_T = o.PipeDate_T.slice(0, 10).replaceAll("-", ".");
            }
            if (o.LastPipelineDate) {
              o.LastPipelineDate = o.LastPipelineDate.slice(0, 10).replaceAll(
                "-",
                "."
              );
            }
            o.Model = o.Model.toUpperCase();

            if (o.DSource === "1.-Forgalomba helyezett") {
              temp2.push(o);
            }
            if (o.DSource === "2.-Tervezett") {
              if(o.LastPipelineName === "609 - Átírás megtörtént"){
                temp2.push(o)
              }else{
                temp1.push(o);
              }
            }
          });
          setFilterData([...temp1, ...temp2]);
          setData1(temp1);
          setData2(temp2);
          setLoading(false);
        }
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  }

  useEffect(() => {
    setLoading(true);
    fetchData();
    setWorkingData1(data1);
    setWorkingData2(data2);
  }, [dateValue]);

  useEffect(() => {
    let smar = 0;
    let hmar = 0;
    let ster = 0;
    let hter = 0;
    workingData1.forEach((element) => {
      if (element.VehicleCategory === "S=Személygépkocsi") {
        ster++;
      }
      if (element.VehicleCategory === "H=Haszongépjármű") {
        hter++;
      }
    });
    workingData2.forEach((element) => {
      if (element.VehicleCategory === "S=Személygépkocsi") {
        smar++;
      }
      if (element.VehicleCategory === "H=Haszongépjármű") {
        hmar++;
      }
    });

    setGrid((values) => ({
      ...values,
      ster: ster,
      hter: hter,
      smar: smar,
      hmar: hmar,
    }));
  }, [workingData1, workingData2]);

  //filters
  useEffect(() => {
    let result1 = data1;
    let result2 = data2;

    //VehicleCategory filter
    if (inputs.VehicleCategory !== "Összes") {
      result1 = result1.filter((e) => {
        return e.VehicleCategory === inputs.VehicleCategory;
      });
      result2 = result2.filter((e) => {
        return e.VehicleCategory === inputs.VehicleCategory;
      });
    }

    //Make filter
    if (inputs.Make !== "Összes") {
      result1 = result1.filter((e) => {
        return e.Make === inputs.Make;
      });
      result2 = result2.filter((e) => {
        return e.Make === inputs.Make;
      });
    }

    //Model filter
    if (inputs.Model !== "Összes") {
      result1 = result1.filter((e) => {
        return e.Model === inputs.Model;
      });
      result2 = result2.filter((e) => {
        return e.Model === inputs.Model;
      });
    }

    //Salesman filter
    if (inputs.Salesman !== "Összes") {
      result1 = result1.filter((e) => {
        return e.Salesman === inputs.Salesman;
      });
      result2 = result2.filter((e) => {
        return e.Salesman === inputs.Salesman;
      });
    }

    //InstanceStatus filter
    if (inputs.InstanceStatus !== "Összes") {
      result1 = result1.filter((e) => {
        return e.InstanceStatus === inputs.InstanceStatus;
      });
      result2 = result2.filter((e) => {
        return e.InstanceStatus === inputs.InstanceStatus;
      });
    }

    setWorkingData1(result1);
    setWorkingData2(result2);
  }, [inputs, data1, data2]);

  const fillCategory = () => {
    let temp = [];
    filterData.forEach((o) => {
      if (temp.indexOf(o.VehicleCategory) === -1) {
        temp.push(o.VehicleCategory);
      }
    });
    temp.sort();
    temp.push("Összes");
    return temp.map((e) => {
      let flag = false;
      workingData1.forEach((o) => {
        if (o.VehicleCategory === e) {
          flag = true;
        }
      });
      workingData2.forEach((o) => {
        if (o.VehicleCategory === e) {
          flag = true;
        }
      });
      if (flag || e === "Összes") {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#000000" }}>
            {e}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#a8a8a8" }}>
            {e}
          </MenuItem>
        );
      }
    });
  };
  const fillMake = () => {
    let temp = [];
    filterData.forEach((o) => {
      if (temp.indexOf(o.Make) === -1) {
        temp.push(o.Make);
      }
    });
    temp.sort();
    temp.push("Összes");
    return temp.map((e) => {
      let flag = false;
      workingData1.forEach((o) => {
        if (o.Make === e) {
          flag = true;
        }
      });
      workingData2.forEach((o) => {
        if (o.Make === e) {
          flag = true;
        }
      });
      if (flag || e === "Összes") {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#000000" }}>
            {e}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#a8a8a8" }}>
            {e}
          </MenuItem>
        );
      }
    });
  };
  const fillModel = () => {
    let temp = [];
    filterData.forEach((o) => {
      if (temp.indexOf(o.Model) === -1) {
        temp.push(o.Model);
      }
    });
    temp.sort();
    temp.push("Összes");
    return temp.map((e) => {
      let flag = false;
      workingData1.forEach((o) => {
        if (o.Model === e) {
          flag = true;
        }
      });
      workingData2.forEach((o) => {
        if (o.Model === e) {
          flag = true;
        }
      });
      if (flag || e === "Összes") {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#000000" }}>
            {e}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#a8a8a8" }}>
            {e}
          </MenuItem>
        );
      }
    });
  };
  const fillSalesman = () => {
    let temp = [];
    filterData.forEach((o) => {
      if (temp.indexOf(o.Salesman) === -1) {
        temp.push(o.Salesman);
      }
    });
    temp.sort();
    temp.push("Összes");
    return temp.map((e) => {
      let flag = false;
      workingData1.forEach((o) => {
        if (o.Salesman === e) {
          flag = true;
        }
      });
      workingData2.forEach((o) => {
        if (o.Salesman === e) {
          flag = true;
        }
      });
      if (flag || e === "Összes") {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#000000" }}>
            {e}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#a8a8a8" }}>
            {e}
          </MenuItem>
        );
      }
    });
  };
  const fillStatus = () => {
    let temp = [];
    filterData.forEach((o) => {
      if (temp.indexOf(o.InstanceStatus) === -1) {
        temp.push(o.InstanceStatus);
      }
    });
    temp.sort();
    temp.push("Összes");
    return temp.map((e) => {
      let flag = false;
      data1.forEach((o) => {
        if (o.InstanceStatus === e) {
          flag = true;
        }
      });
      workingData2.forEach((o) => {
        if (o.InstanceStatus === e) {
          flag = true;
        }
      });
      if (flag || e === "Összes") {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#000000" }}>
            {e}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={e} value={e} sx={{ color: "#a8a8a8" }}>
            {e}
          </MenuItem>
        );
      }
    });
  };

  const ref = createRef(null);

  return (
    <>
      <NavBar reference={ref} />
      <Popper
        open={open}
        placement="top-end"
        popperOptions={{ positionFixed: false }}
        style={{
          position: "absolute",
          bottom: "unset",
          right: 150,
          top: 65,
          left: "unset",
          width: "410px",
        }}
      >
        <div>
          <Grid container spacig={2}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>Személy</Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>Haszon</Item>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>Tervezett</Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>{grid.ster}</Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>{grid.hter}</Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>{grid.hter + grid.ster}</Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>Forg. helyezett</Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>{grid.smar}</Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>{grid.hmar}</Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>{grid.hmar + grid.smar}</Item>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>{grid.ster + grid.smar}</Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>{grid.hter + grid.hmar}</Item>
            </Grid>
            <Grid item xs={3}>
              <Item sx={{ fontSize: 12 }}>
                {grid.hter + grid.hmar + grid.ster + grid.smar}
              </Item>
            </Grid>
          </Grid>
        </div>
      </Popper>
      <div ref={ref}>
        <Stack direction="row" spacing={1} alignItems="center">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="category-label">Kategória</InputLabel>
            <Select
              labelId="category-label"
              label="Kategória"
              id="VehicleCategory"
              name="VehicleCategory"
              value={inputs.VehicleCategory}
              onChange={handleChange}
            >
              {fillCategory()}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="make-label">Márka</InputLabel>
            <Select
              labelId="make-label"
              label="Márka"
              id="Make"
              name="Make"
              value={inputs.Make}
              onChange={handleChange}
            >
              {fillMake()}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="modell-label">Modell</InputLabel>
            <Select
              labelId="modell-label"
              label="Modell"
              id="Model"
              name="Model"
              value={inputs.Model}
              onChange={handleChange}
            >
              {fillModel()}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="salesperson-label">Értékesítő</InputLabel>
            <Select
              labelId="salesperson-label"
              label="Értékesítő"
              id="Salesman"
              name="Salesman"
              value={inputs.Salesman}
              onChange={handleChange}
            >
              {fillSalesman()}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="status-label">Állapot</InputLabel>
            <Select
              labelId="status-label"
              label="Állapot"
              id="InstanceStatus"
              name="InstanceStatus"
              value={inputs.InstanceStatus}
              onChange={handleChange}
            >
              {fillStatus()}
            </Select>
          </FormControl>

          <Button
            variant="text"
            onClick={() => {
              setDateValue(
                new Date(dateValue.getFullYear(), dateValue.getMonth() - 1, 10)
              );
            }}
          >
            &#8592;
          </Button>

          <LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
            <div className="datepicker">
              <DatePicker
                views={["year", "month"]}
                label="Dátum"
                minDate={new Date("2012-03-01")}
                maxDate={new Date("2023-06-01")}
                value={dateValue}
                onChange={(newValue) => {
                  setDateValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </div>
          </LocalizationProvider>

          <Button
            variant="text"
            onClick={() => {
              setDateValue(
                new Date(dateValue.getFullYear(), dateValue.getMonth() + 1, 10)
              );
            }}
          >
            &#8594;
          </Button>

          <Button
            variant="outlined"
            id="asd"
            onClick={(e) => {
              setInputs((values) => ({
                ...values,
                VehicleCategory: "Összes",
                Make: "Összes",
                Model: "Összes",
                Salesman: "Összes",
                InstanceStatus: "Összes",
              }));
              setDateValue(new Date());
            }}
          >
            Reset
          </Button>
        </Stack>

        <h1>Tervezett - {grid.ster + grid.hter} db</h1>
        {errorMessage ? (
          <div>{errorMessage.toString()}</div>
        ) : (
          <EtrTable headers={columns1} data={workingData1} loading={loading} />
        )}

        <h1>Már forgalomba helyezett - {grid.smar + grid.hmar} db</h1>
        {errorMessage ? (
          <div>{errorMessage.toString()}</div>
        ) : (
          <EtrTable headers={columns2} data={workingData2} loading={loading} />
        )}
      </div>
    </>
  );
}

export default Tervezes;
