import React, { useState, useEffect, createRef } from "react";
import NavBar from "../components/NavBar.js";
import { useNavigate } from "react-router-dom";
import {
  Select,
  InputLabel,
  Button,
  MenuItem,
  Input,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  Stack,
} from "@mui/material";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";
import config from "../resources/config.json";
import RegisterDialog from "../components/settings/RegisterDialog.js";
import cookie from "react-cookies";
import { ForkLeft } from "@mui/icons-material";

function Settings() {
  const ref = createRef(null);
  let navigate = useNavigate();
  const [user, setUser] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [fullname, setFullname] = useState("");
  const [defaultSite, setDefaultSite] = useState("/tervezes");
  const [defaultMake, setDefaultMake] = useState("OPEL");
  const [role, setRole] = useState("ertekesito");

  function resetStates() {
    setUsername("");
    setPassword("");
    setFullname("");
    setDefaultSite("/tervezes");
    setRole("ertekesito");
  }

  function fetchData() {
    fetch(config.apiurl + "/etr/getUsers", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setUsers(response.users);
      });
  }

  function updateUserAdmin() {
    let postData = {
      prev_username: user,
      username: username,
      password: password,
      fullname: fullname,
      defaultSite: defaultSite,
      defaultMake: defaultMake,
      roleName: role,
    };

    fetch(config.apiurl + "/etr/updateUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          toast.error(response.error);
        } else {
          toast.success("Felhasználó frissítve!");
        }
        fetchData();
      });
    setUser("");
    resetStates();
  }

  function updateUserNoadmin() {
    let postData = {};
    users.forEach((e) => {
      if (e.Username === user) {
        let formattedPerm = "";
        e.roles.forEach((e) => {
          formattedPerm = formattedPerm + e.toLowerCase() + ",";
        });
        if (formattedPerm) {
          formattedPerm = formattedPerm.slice(0, -1);
        }

        postData = {
          prev_username: user,
          username: user,
          password: password,
          fullname: e.Fullname,
          defaultSite: defaultSite,
          defaultMake: defaultMake,
          permissions: formattedPerm,
        };
      }
    });

    fetch(config.apiurl + "/etr/updateUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          toast.error(response.error);
        } else {
          toast.success("Felhasználó frissítve! Kérem várjon...");
          setTimeout(() => {
            navigate("/login");
            removeCookie("user");
          }, "1000");
        }
        fetchData();
      });
    setUser("");
    resetStates();
  }

  function deleteUser() {
    let postData = {
      username: user,
    };

    fetch(config.apiurl + "/etr/deleteUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          toast.error(response.error);
        } else {
          toast.success("Felhasználó törölve!");
        }
        fetchData();
      });
    setUser("");
    resetStates();
  }

  const handleChange = (event) => {
    fillInputs(event.target.value);
    setUser(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fillUsers = () => {
    return users.map((e) => {
      return (
        <MenuItem key={e.Username} value={e.Username}>
          {e.Username}
        </MenuItem>
      );
    });
  };

  const fillInputs = (user) => {
    users.forEach((e) => {
      if (e.Username === user) {
        setUsername(e.Username);
        setFullname(e.Fullname);
        setDefaultSite(e.DefaultSite);
        setDefaultMake(e.DefaultMake);
        setRole(e.RoleName);
      }
    });
  };

  const handleDialogClose = (value) => {
    if (value !== null) {
      if (value.error) {
        toast.error(value.errorMessage);
      } else {
        toast.success(value.status);
      }
    }

    setOpenDialog(false);
    fetchData();
  };

  return (
    <>
      <Toaster position="top-center" />
      <NavBar reference={ref} />

      <div ref={ref}>
        <div>
          <Button
            variant="contained"
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            Új Felhasználó regisztrálása
          </Button>
          <RegisterDialog
            open={openDialog}
            onClose={(value) => handleDialogClose(value)}
          />

          <InputLabel id="userSelectLabel">Felhasználó</InputLabel>
          <Select
            labelId="userSelectLabel"
            id="userSelect"
            value={user}
            label="User"
            onChange={handleChange}
          >
            {fillUsers()}
          </Select>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "30%",
            }}
          >
            <InputLabel
              sx={{
                margin: "10px",
              }}
              htmlFor="felhasználónév"
            >
              Felhasználónév
            </InputLabel>
            <Input
              id="felhasználónév"
              sx={{
                margin: "10px",
              }}
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
              }}
            />

            <InputLabel
              sx={{
                margin: "10px",
              }}
              htmlFor="jelszo"
            >
              Új jelszó
            </InputLabel>
            <Input
              id="jelszo"
              type="password"
              sx={{
                margin: "10px",
              }}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />

            <InputLabel
              sx={{
                margin: "10px",
              }}
              htmlFor="fullname"
            >
              Teljes név
            </InputLabel>
            <Input
              id="fullname"
              sx={{
                margin: "10px",
              }}
              value={fullname}
              onChange={(event) => {
                setFullname(event.target.value);
              }}
            />

            <Stack direction="row">
              <div>
                <FormLabel
                  sx={{
                    margin: "10px",
                  }}
                  component="legend"
                >
                  Alapértelmezett oldal:
                </FormLabel>
                <RadioGroup
                  defaultValue="/tervezes"
                  sx={{
                    margin: "10px",
                  }}
                  value={defaultSite}
                  onChange={(e) => {
                    setDefaultSite(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="/tervezes"
                    control={<Radio />}
                    label="Tervezés"
                  />
                  <FormControlLabel
                    value="/forgalom"
                    control={<Radio />}
                    label="Forgalom"
                  />
                  <FormControlLabel
                    value="/uj"
                    control={<Radio />}
                    label="Új"
                  />
                </RadioGroup>
              </div>

              <div>
                <FormLabel
                  sx={{
                    margin: "10px",
                  }}
                  component="legend"
                >
                  Alapértelmezett filter tervezésben:
                </FormLabel>
                <RadioGroup
                  defaultValue="OPEL"
                  sx={{
                    margin: "10px",
                  }}
                  value={defaultMake}
                  onChange={(e) => {
                    setDefaultMake(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="OPEL"
                    control={<Radio />}
                    label="Opel"
                  />
                  <FormControlLabel
                    value="SUZUKI"
                    control={<Radio />}
                    label="Suzuki"
                  />
                  <FormControlLabel
                    value="ISUZU"
                    control={<Radio />}
                    label="Isuzu"
                  />
                  <FormControlLabel
                    value="Összes"
                    control={<Radio />}
                    label="Összes"
                  />
                </RadioGroup>
              </div>
              <div>
                <FormLabel
                  sx={{
                    margin: "10px",
                  }}
                  component="legend"
                >
                  Role:
                </FormLabel>
                <RadioGroup
                  defaultValue="ertekesito"
                  sx={{
                    margin: "10px",
                  }}
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="ertekesito"
                    control={<Radio />}
                    label="Értékesítő"
                  />
                  <FormControlLabel
                    value="admin"
                    control={<Radio />}
                    label="Admin"
                  />
                  <FormControlLabel
                    value="god"
                    control={<Radio />}
                    label="Moderátor"
                  />
                </RadioGroup>
              </div>
            </Stack>

            <div>
              <Button
                variant="contained"
                sx={{
                  margin: "10px",
                }}
                onClick={updateUserAdmin}
              >
                Mentés
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FC0000",
                  width: "60%",
                  "&:hover": {
                    backgroundColor: "#cc0000",
                  },
                }}
                onClick={deleteUser}
              >
                Felhasználó törlése
              </Button>
            </div>
          </div>
        </div>
        {/*
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "30%",
              }}
            >
              <Stack direction="row">
                <div>
                  <InputLabel id="userSelectLabel">
                    Munka átadása kinek?
                  </InputLabel>
                  <Select
                    labelId="userSelectLabel"
                    id="userSelect"
                    value={userNoAdmin}
                    label="User"
                    onChange={(event) => {
                      setUserNoAdmin(event.target.value);
                    }}
                  >
                    {fillUsers()}
                  </Select>
                </div>

                <Button
                  variant="contained"
                  sx={{
                    margin: "10px",
                  }}
                  onClick={() => {}}
                >
                  Szabadság
                </Button>
              </Stack>

              <InputLabel
                sx={{
                  margin: "10px",
                }}
                htmlFor="jelszo"
              >
                Új jelszó
              </InputLabel>
              <Input
                id="jelszo"
                type="password"
                sx={{
                  margin: "10px",
                }}
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />

              <Stack direction="row">
                <div>
                  <FormLabel
                    sx={{
                      margin: "10px",
                    }}
                    component="legend"
                  >
                    Alapértelmezett oldal:
                  </FormLabel>
                  <RadioGroup
                    defaultValue="/tervezes"
                    sx={{
                      margin: "10px",
                    }}
                    value={defaultSite}
                    onChange={(e) => {
                      setDefaultSite(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="/tervezes"
                      control={<Radio />}
                      label="Tervezés"
                    />
                    <FormControlLabel
                      value="/forgalom"
                      control={<Radio />}
                      label="Forgalom"
                    />
                    <FormControlLabel
                      value="/uj"
                      control={<Radio />}
                      label="Új"
                    />
                  </RadioGroup>
                </div>

                <div>
                  <FormLabel
                    sx={{
                      margin: "10px",
                    }}
                    component="legend"
                  >
                    Alapértelmezett filter tervezésben:
                  </FormLabel>
                  <RadioGroup
                    defaultValue="OPEL"
                    sx={{
                      margin: "10px",
                    }}
                    value={defaultMake}
                    onChange={(e) => {
                      setDefaultMake(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="OPEL"
                      control={<Radio />}
                      label="Opel"
                    />
                    <FormControlLabel
                      value="SUZUKI"
                      control={<Radio />}
                      label="Suzuki"
                    />
                    <FormControlLabel
                      value="ISUZU"
                      control={<Radio />}
                      label="Isuzu"
                    />
                    <FormControlLabel
                      value="Összes"
                      control={<Radio />}
                      label="Összes"
                    />
                  </RadioGroup>
                </div>
              </Stack>
            </div>

            <Button
              variant="contained"
              sx={{
                margin: "10px",
              }}
              onClick={updateUserNoadmin}
            >
              Mentés
            </Button>
          </div>
        )*/}
      </div>
    </>
  );
}

export default Settings;
