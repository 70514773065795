import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
} from "@mui/material";
import config from "../../resources/config.json";
import { useCookies } from "react-cookie";

function RegisterDialog({ open, onClose }) {
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [userError, setUserError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [fullname, setFullname] = useState("");
  const [defaultSite, setDefaultSite] = useState("/tervezes");
  const [defaultMake, setDefaultMake] = useState("OPEL");
  const [role, setRole] = useState("ertekesito");

  const handleClose = () => {
    resetStates();
    onClose(null);
  };

  function registerUser() {
    let postData = {
      username: username,
      password: password,
      fullname: fullname,
      defaultSite: defaultSite,
      defaultMake: defaultMake,
      roleName: role,
    };

    fetch(config.apiurl + "/etr/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          resetStates();
          onClose(response);
        } else {
          resetStates();
          onClose(response);
        }
      });
  }

  function resetStates() {
    setUserError(false);
    setPassError(false);
    setNameError(false);

    setUsername("");
    setPassword("");
    setFullname("");
    setDefaultSite("/tervezes");
    setDefaultMake("OPEL");
  }

  const handleRegister = async () => {
    if (username && password && fullname) {
      registerUser();
    } else {
      if (!username) {
        setUserError(true);
      } else {
        setUserError(false);
      }
      if (!password) {
        setPassError(true);
      } else {
        setPassError(false);
      }
      if (!fullname) {
        setNameError(true);
      } else {
        setNameError(false);
      }
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth={"xl"} onClose={handleClose} open={open}>
      <DialogTitle>Új felhasználó</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>Mégsem</Button>
      </DialogActions>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "30%",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <FormControl required={true} error={userError}>
          <InputLabel htmlFor="felhasználónév">Felhasználónév</InputLabel>
          <Input
            id="felhasználónév"
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
        </FormControl>
        <br></br>
        <FormControl required={true} error={passError}>
          <InputLabel htmlFor="jelszó">Jelszó</InputLabel>
          <Input
            id="jelszó"
            type="password"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </FormControl>
        <br />
        <FormControl required={true} error={nameError}>
          <InputLabel htmlFor="fullname">Teljes Név</InputLabel>
          <Input
            id="fullname"
            value={fullname}
            onChange={(event) => {
              setFullname(event.target.value);
            }}
          />
        </FormControl>
        <br />
        <Stack direction="row">
          <div>
            <FormLabel
              sx={{
                margin: "10px",
              }}
              component="legend"
            >
              Alapértelmezett oldal:
            </FormLabel>
            <RadioGroup
              defaultValue="/tervezes"
              sx={{
                margin: "10px",
              }}
              value={defaultSite}
              onChange={(e) => {
                setDefaultSite(e.target.value);
              }}
            >
              <FormControlLabel
                value="/tervezes"
                control={<Radio />}
                label="Tervezés"
              />
              <FormControlLabel
                value="/forgalom"
                control={<Radio />}
                label="Forgalom"
              />
              <FormControlLabel value="/uj" control={<Radio />} label="Új" />
            </RadioGroup>
          </div>

          <div>
            <FormLabel
              sx={{
                margin: "10px",
              }}
              component="legend"
            >
              Alapértelmezett filter tervezésben:
            </FormLabel>
            <RadioGroup
              defaultValue="OPEL"
              sx={{
                margin: "10px",
              }}
              value={defaultMake}
              onChange={(e) => {
                setDefaultMake(e.target.value);
              }}
            >
              <FormControlLabel value="OPEL" control={<Radio />} label="Opel" />
              <FormControlLabel
                value="SUZUKI"
                control={<Radio />}
                label="Suzuki"
              />
              <FormControlLabel
                value="ISUZU"
                control={<Radio />}
                label="Isuzu"
              />
              <FormControlLabel
                value="Összes"
                control={<Radio />}
                label="Összes"
              />
            </RadioGroup>
          </div>
          <div>
            <FormLabel
              sx={{
                margin: "10px",
              }}
              component="legend"
            >
              Role:
            </FormLabel>
            <RadioGroup
              defaultValue="ertekesito"
              sx={{
                margin: "10px",
              }}
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
              }}
            >
              <FormControlLabel
                value="ertekesito"
                control={<Radio />}
                label="Értékesítő"
              />
              <FormControlLabel
                value="admin"
                control={<Radio />}
                label="Admin"
              />
              <FormControlLabel
                value="god"
                control={<Radio />}
                label="Moderátor"
              />
            </RadioGroup>
          </div>
        </Stack>

        <Button
          sx={{
            margin: "10px",
          }}
          variant="contained"
          onClick={handleRegister}
        >
          Mentés
        </Button>
      </div>
    </Dialog>
  );
}

export default RegisterDialog;
