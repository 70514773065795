import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useCookies } from "react-cookie";

import config from "../../resources/config.json";

function EditWorkflowDialog({ open, selectedId, data, onClose, workflows }) {
  const [status, setStatus] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [law, setLaw] = useState(null);

  let selectedWorkflow = selectedId
    ? data.find((element) => element.IDCases === selectedId.original.IDCases)
        .workflow
    : [];
  var chosenWorkflow = [];
  var remainingWorkflow = workflows;

  selectedWorkflow.forEach((workflow) => {
    let element = workflows.find((wf) => wf.id === workflow);
    chosenWorkflow.push(element);
    remainingWorkflow = remainingWorkflow.filter(
      (item) => item.id !== workflow
    );
  });
  //TODO - change find argument when the selectedId changes to another key

  const handleClose = () => {
    onClose(null);
  };

  const handleListItemClick = () => {
    onClose({
      workflow: columns.chosen.items
        .map((item) => {
          return item.id;
        })
        .join(","),
      law: law,
      status: status,
    });
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const columnData = {
    chosen: {
      name: "Aktív",
      items: [],
    },
    remaining: {
      name: "Inaktív",
      items: [],
    },
  };

  useEffect(() => {
    setColumns({
      chosen: {
        name: "Aktív",
        items: chosenWorkflow,
      },
      remaining: {
        name: "Inaktív",
        items: remainingWorkflow,
      },
    });
    fillInputs();
  }, [selectedId]);

  const [columns, setColumns] = useState({ columnData });

  const fillInputs = () => {
    if (selectedId) {
      setStatus(selectedId.original.InstanceStatus);
      setLaw(selectedId.original.Law);
    } else {
      setStatus(null);
      setLaw(null);
    }
    /*setStatus()
    setLaw()*/
  };

  return (
    <Dialog fullWidth={true} maxWidth={"xl"} onClose={handleClose} open={open}>
      <DialogTitle>Munkafolyamat módosítás</DialogTitle>
      <DialogActions></DialogActions>

      {!selectedId ? (
        <p>Nincs kiválasztott ügy</p>
      ) : (
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack
            direction="column"
            spacing={1}
            alignItems="left"
            sx={{ margin: "20px" }}
          >
            <FormLabel
              sx={
                {
                  //margin: "10px",
                }
              }
              component="legend"
            >
              Kategória:
            </FormLabel>
            <RadioGroup
              defaultValue="/tervezes"
              sx={{
                margin: "10px",
              }}
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <FormControlLabel
                value="N=Új-Retail"
                control={<Radio />}
                label="Új"
              />
              <FormControlLabel
                value="H=Használt"
                control={<Radio />}
                label="Használt"
              />
              <FormControlLabel
                value="T=Taktikai forg.hely"
                control={<Radio />}
                label="Taktikai"
              />
            </RadioGroup>
            <FormLabel
              sx={{
                margin: "10px",
              }}
              component="legend"
            >
              Jogi személy:
            </FormLabel>
            <RadioGroup
              defaultValue="/tervezes"
              sx={{
                margin: "10px",
              }}
              value={law}
              onChange={(e) => {
                setLaw(e.target.value);
              }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Igen" />
              <FormControlLabel value={0} control={<Radio />} label="Nem" />
            </RadioGroup>
          </Stack>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              position: "relative",
              top: "-80px",
            }}
          >
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            >
              {Object.entries(columns).map(([columnId, column], index) => {
                return (
                  <span
                    style={{
                      width: "fit-content",
                      float: "left",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    key={columnId}
                  >
                    <h2>{column.name}</h2>
                    <div style={{ margin: 8 }}>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? "lightblue"
                                  : "lightgrey",
                                padding: 4,
                                width: 250,
                                minHeight: 500,
                              }}
                            >
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: "none",
                                            padding: 10,
                                            margin: "0 0 8px 0",
                                            //minHeight: "50px",
                                            backgroundColor: snapshot.isDragging
                                              ? "#263B4A"
                                              : "#456C86",
                                            color: "white",
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          {item.name}
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </span>
                );
              })}
            </DragDropContext>
          </div>
          <Stack
            direction="column"
            spacing={1}
            alignItems="left"
            sx={{ margin: "20px" }}
          >
            <Button onClick={handleListItemClick} autoFocus>
              Mentés
            </Button>
            <Button onClick={handleClose}>Mégsem</Button>
          </Stack>
        </Stack>
      )}
    </Dialog>
  );
}

export default EditWorkflowDialog;
