import {
  BottomNavigation,
  BottomNavigationAction,
  IconButton,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import LinkIcon from "@mui/icons-material/Link";
import CarRentalIcon from "@mui/icons-material/CarRental";
import RefreshIcon from "@mui/icons-material/Refresh";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import ReportIcon from "@mui/icons-material/Report";
import HelpIcon from "@mui/icons-material/Help";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, { useState, useEffect, createRef } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import "../css/navBar.css";
import config from "../resources/config.json";
import ReportDialog from "./NavBar/ReportDialog";
import toast, { Toaster } from "react-hot-toast";
import { useScreenshot, createFileName } from "use-react-screenshot";
import pdf from "../pdf/ETR_felhasználói_kézikönyv.pdf";

function NavBar({ reference }) {
  let navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [style, setStyle] = useState([]);
  const [newAlert, setNewAlert] = useState(false);
  const [settings, setSettings] = useState(false);
  const [lastRefresh, setLastRefresh] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState("");
  const [users, setUsers] = useState([]);
  const [roleName, setroleName] = useState();
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  useEffect(() => {
    var flag = false;
    console.log(cookies.user.MakeRights);
    switch (cookies.user.Role) {
      case "god":
        setroleName("Moderátor");
        break;
      case "ertekesito":
        setroleName("Értékesítő");
        break;
      case "admin":
        setroleName("Admin");
        break;
      default:
        setroleName("???");
    }

    fetch(config.apiurl + "/etr/getCases", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
    }).then((response) => {
      if (response.status === 401) {
        removeCookie("user");
      }
    });

    fetch(config.apiurl + "/etr/getCases", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          response.cases.forEach((e) => {
            if (lastRefresh === null) {
              setLastRefresh(new Date(e.timestamp));
            } else {
              if (new Date(e.timestamp) > lastRefresh) {
                setLastRefresh(new Date(e.timestamp));
              }
            }
            if (e.new === 1) {
              flag = true;
            }
          });
          if (flag) {
            setStyle({
              color: "red",
            });
            setNewAlert(true);
          } else {
            setStyle({});
            setNewAlert(false);
          }
          fetch(config.apiurl + "/etr/getUsers", {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Token: cookies.user.Token,
            },
          })
            .then((response) => response.json())
            .then((response) => {
              let temp = [];
              response.users.forEach((u) => {
                if (
                  u.RoleName === "admin" &&
                  u.onLeave === "0" &&
                  u.Username !== cookies.user.Username
                ) {
                  temp.push(u);
                }
              });
              setUsers(temp);
            });
        } else {
          console.log(response.error);
        }
      });
  }, [refresh]);

  const sendBug = (image, value) => {
    let postData = {
      image: image,
      description: value,
      user: cookies.user.FullName,
    };
    fetch(config.apiurl + "/etr/sendBug", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: cookies.user.Token,
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
      });
  };

  const postImage = (value) => {
    takeScreenShot(reference.current).then((image) => {
      sendBug(image, value);
    });
  };

  const handleDialogClose = (value) => {
    console.log(value);
    if (value === "") {
      console.log("error");
      toast.error("Üres hibaüzenet");
    } else if (value !== null) {
      postImage(value);
      toast.success("Elküldve!");
    }
    setOpenDialog(false);
  };

  const handleChange = (event) => {
    setUser(event.target.value);
  };

  const fillUsers = () => {
    return users.map((e) => {
      return (
        <MenuItem key={e.Username} value={e.Username}>
          {e.Fullname}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <Toaster position="top-center" />
      <BottomNavigation
        sx={{ top: 0, left: 0, right: 0 }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (newValue === "/") {
            removeCookie("user");
          }
          navigate(newValue);
        }}
      >
        <div className="welcomeLabel">
          Szia, <b>{cookies.user.FullName} </b> ({roleName}) !
        </div>
        {cookies.user.Role === "admin" ? (
          cookies.user.TempMakeRights ? (
            <div className="welcomeLabel">
              Elérhető márkák: <b>{cookies.user.MakeRights.toUpperCase()}</b>
            </div>
          ) : (
            <div className="welcomeLabel">
              Elérhető márkák:{" "}
              <b>{cookies.user.TempMakeRights.toUpperCase()}</b>
            </div>
          )
        ) : null}

        <IconButton
          onClick={() => {
            window.open(
              "http://intranet2.tormasi.hu/Lists/Utalsi%20terv/Allitemsg.aspx"
            );
          }}
        >
          <LinkIcon />
          <span>Utalási Terv</span>
        </IconButton>

        <div className="space1" />
        <BottomNavigationAction
          label="Tervezés"
          value="/tervezes"
          icon={<DirectionsCarIcon />}
        />
        <BottomNavigationAction
          label="Forgalomba helyezés"
          value="/forgalom"
          icon={<CarRentalIcon />}
        />
        {cookies.user.Role !== "ertekesito" ? (
          <BottomNavigationAction
            label="Új Ügy"
            value="/uj"
            icon={newAlert ? <AddCircleIcon /> : <AddIcon />}
            sx={style}
          />
        ) : null}

        {cookies.user.Role === "god" ? (
          <BottomNavigationAction
            label="Beállítások"
            value="/beallitasok"
            icon={<SettingsIcon />}
          />
        ) : null}

        <div className="space2" />
        {cookies.user.Role === "admin" ? (
          cookies.user.onLeave === "0" ? (
            <Stack direction="row" spacing={2}>
              <Select
                labelId="userSelectLabel"
                id="userSelect"
                value={user}
                label="User"
                onChange={handleChange}
              >
                {fillUsers()}
              </Select>
              <IconButton
                disabled={user === ""}
                onClick={() => {
                  let postData = {
                    sourceUser: cookies.user.Username,
                    targetUser: user,
                  };
                  fetch(config.apiurl + "/etr/givePermission", {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Token: cookies.user.Token,
                    },
                    body: JSON.stringify(postData),
                  })
                    .then((response) => response.json())
                    .then((response) => {
                      if (response.error) {
                        toast.error("Hiba");
                      } else {
                        toast.success("Szabadság jegyezve!");
                        setTimeout(function () {
                          navigate("/login");
                        }, 1000);
                      }
                    });
                }}
              >
                <BeachAccessIcon />
              </IconButton>
            </Stack>
          ) : (
            <IconButton
              sx={{
                color: "red",
              }}
              onClick={() => {
                let postData = {
                  user: cookies.user.Username,
                };
                fetch(config.apiurl + "/etr/revokePermission", {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Token: cookies.user.Token,
                  },
                  body: JSON.stringify(postData),
                })
                  .then((response) => response.json())
                  .then((response) => {
                    if (response.error) {
                      toast.error("Hiba");
                    } else {
                      toast.success("Szabadság vége!");
                      const dateValue = new Date();
                      setCookie(
                        "user",
                        { ...cookies.user, onLeave: "0" },
                        {
                          path: "/",
                          expires: new Date(
                            dateValue.getFullYear() + 10,
                            dateValue.getMonth(),
                            10
                          ),
                        }
                      );
                    }
                  });
              }}
            >
              <BeachAccessIcon />
            </IconButton>
          )
        ) : null}

        <IconButton href={pdf} target="_blank">
          <HelpIcon />
        </IconButton>

        <IconButton
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          <ReportIcon />
        </IconButton>
        <ReportDialog
          open={openDialog}
          onClose={(value) => handleDialogClose(value)}
        />

        <IconButton
          aria-label="refresh"
          onClick={() => {
            fetch(config.apiurl + "/etr/refreshProcedures", {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Token: cookies.user.Token,
              },
            })
              .then((response) => response.json())
              .then((response) => {
                setRefresh(!refresh);
                toast.success("Frissítve!");
                if (response.error) {
                  console.log(response.error);
                }
              });
          }}
        >
          <RefreshIcon />
        </IconButton>

        {/*lastRefresh ? (
        <div>{lastRefresh.toISOString().slice(0, 19).replaceAll("T", " ")}</div>
      ) : (
        <div>Nincs adat</div>
      )*/}

        <BottomNavigationAction
          label="Kijelentkezés"
          value="/"
          icon={<LogoutIcon />}
        />
      </BottomNavigation>
    </>
  );
}

export default NavBar;
