import React, { useState, useEffect } from "react";
import { Button, Input, FormControl, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import config from "../resources/config.json";

function Login() {
  let navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  function handleLogin() {
    const data = {
      username: inputs.username,
      password: inputs.password,
    };

    fetch(config.apiurl + "/etr/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          const dateValue = new Date();
          setErrorMessage("");
          setCookie("user", response, {
            path: "/",
            expires: new Date(
              dateValue.getFullYear() + 10,
              dateValue.getMonth(),
              10
            ),
          });
          console.log(response);
          navigate(response.DefaultSite);
        } else {
          setErrorMessage(response.error);
        }
      });
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "20%",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Username</InputLabel>
          <Input
            name="username"
            value={inputs.username}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Password</InputLabel>
          <Input
            name="password"
            value={inputs.password}
            onChange={handleChange}
            type="password"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleLogin();
              }
            }}
          />
        </FormControl>
        <Button
          variant="contained"
          onClick={() => {
            handleLogin();
          }}
        >
          Login
        </Button>
        {errorMessage && <p className="error"> {errorMessage} </p>}
      </div>
    </>
  );
}

export default Login;
