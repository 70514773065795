import React, { useState, useEffect, useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@mui/material";
import logo from "../resources/loading.gif";

function EtrTable(props) {
  const data = props.data;
  const columns = useMemo(() => props.headers, []);

  const tableInstance = useTable({ columns: columns, data: data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const [selectedId, setSelectedId] = useState(null);
  useEffect(() => {
    setSelectedId(null);
  }, [props.reset]);

  return (
    <>
      {props.loading ? (
        <div>
          <div>Loading...</div>
          <img src={logo} alt="loading..." />
        </div>
      ) : data.length === 0 ? (
        <p>{"Nincs adat"}</p>
      ) : (
        <TableContainer component={Paper}>
          <Table {...getTableProps()} stickyHeader>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <TableRow
                    style={{
                      backgroundColor:
                        selectedId && selectedId.id === row.id ? "#00ADEF" : "",
                    }}
                    onClick={() => {
                      if (props.isSelectable) {
                        if (selectedId && row.id === selectedId.id) {
                          setSelectedId(null);
                          props.parentCallBack(null);
                        } else {
                          setSelectedId(row);
                          props.parentCallBack(row);
                        }
                      }
                    }}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      if (cell.column.Header === "JOB") {
                        return (
                          <Tooltip
                            title={
                              <>
                                <span>
                                  Állapot: {cell.row.original.InstanceStatus}
                                </span>
                                <br />
                                <span>
                                  Értékesítő neve: {cell.row.original.Salesman}
                                </span>
                                <br />
                                <span>
                                  Kategória: {cell.row.original.VehicleCategory}
                                </span>
                                <br />
                                <span>
                                  Márka - Típus: {cell.row.original.Make}{" "}
                                  {cell.row.original.Model}
                                </span>
                                <br />
                                <span>
                                  T - Dátum:{" "}
                                  {cell.row.original.PipeDate_T.slice(0, 10)}
                                </span>
                                <br />
                                <span>
                                  Szerződésszám:{" "}
                                  {cell.row.original.OrderAgreementNumber}
                                </span>
                                <br />
                              </>
                            }
                            placement="right"
                            TransitionComponent={Zoom}
                          >
                            <TableCell {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </TableCell>
                          </Tooltip>
                        );
                      } else {
                        return (
                          <TableCell {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default EtrTable;
