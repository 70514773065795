import { useState, useEffect } from "react";
import config from "../../resources/config.json";
import { useCookies } from "react-cookie";
import {
  Button,
  Dialog,
  TextField,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  Input,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
} from "@mui/material";

function ReportDialog({ open, onClose }) {
  const [error, setError] = useState("");

  const handleClose = () => {
    setError("");
    onClose(null);
  };

  const handleSend = () => {
    setError("");
    onClose(error);
  };

  return (
    <Dialog fullWidth maxWidth="xl" onClose={handleClose} open={open}>
      <DialogTitle>
        <b>Visszajelzés a fejlesztőknek</b>
      </DialogTitle>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "80%",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "600px",
          }}
        >
          <div>Kérem írja le észrevételét:</div>
          <TextField
            multiline
            rows={4}
            value={error}
            onChange={(event) => {
              setError(event.target.value);
            }}
            style={{
              width: "100%",
              margin: "30px",
            }}
          />
          <Stack direction="row">
            <Button
              sx={{
                margin: "10px",
                width: "100px",
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "#cc0000",
                },
              }}
              variant="contained"
              onClick={handleClose}
            >
              Mégsem
            </Button>
            <Button
              sx={{
                margin: "10px",
                width: "100px",
              }}
              variant="contained"
              onClick={handleSend}
            >
              Küldés
            </Button>
          </Stack>
        </div>
      </div>
    </Dialog>
  );
}

export default ReportDialog;
